import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'brand-logo',
    templateUrl: './brand-logo.component.html',
    styleUrls: ['./brand-logo.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class BrandLogoComponent {

}
